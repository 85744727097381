@import '@/styles/_shared.scss';
@import '@/styles/mixins/_breakpoints.scss';
@import '@/styles/variables/_breakpoints.scss';

.collection-list {
  &__container {
    padding: 28px 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-family: $bebas;
      font-size: 60px;
      font-weight: 400;
      margin-bottom: 2px;
      padding: 15px;
    }

    h3 {
      font-family: $frank-ruhl;
      font-size: 32px;
      padding: 15px;
    }

  }

  &__product-list {
    margin-top: 48px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint-down('md') {
      grid-template-columns: repeat(auto, auto);
    }

    @include breakpoint-down('sm') {
      grid-template-columns: repeat(1, 1fr);
    }

    gap: 48px;

  }
}
