.image-reveal {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 5px 5px 16px -1px rgba(0, 0, 0, 0.55);
    user-select: none;

    &__resizer {
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: #fff;
      left: 100%;
      z-index: 2;
      cursor: col-resize;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__resizer-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      padding: 6px;
      background: #fff;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      z-index: 2;
      border: none;
      text-align: center;
    }

    &__base-image {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      user-select: none;
      object-fit: cover;
    }

    &__top-image {
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      user-select: none;
      clip-path: inset(0 0 0 51%);
      object-fit: cover;
      visibility: hidden;
    }

    &::after {
      content: '';
      position: absolute;
      transform: rotate(180deg);
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 2;
    }
  }
}
