@import '@/styles/_shared.scss';

.product-hero-pricing {
  font-size: 26px;
  font-family: $bebas !important;
  color: $pureWhite;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 4px;
  }

  // Market variant overrides
  &.market-variant {
    font-size: 27px;

    @include breakpoint-down('sm') {
      font-size: 20px;
    }

    > div {
      flex-wrap: nowrap;
    }

    &--main {
      color: #D18357;
    }

    &.market-variant &--servings {
      color: white !important;
    }

    &.market-variant &--discounted {
      color: #D18357;
    }

    .portion {
      font-size: 14px;
      color: $gray-600;
    }
  }

  &--base {
    text-decoration: line-through;
    color: $gray-600;
  }

  &--main {
    color: #D18357;
  }

  &--discounted {
    color: #D18357;
  }

  .strikethrough {
    color: #a7a7a7;
    text-decoration: line-through;
    font-size: 0.8em;
  }

  &--servings {
    display: flex;
    color: #a7a7a7;
    flex-direction: column;
    border-left: 2px solid rgba(116, 108, 108, 0.7);
    padding-left: 4px;
  }

  &--servings.portion {
    padding-left: 8px !important;
  }

  .serving {
    justify-content: flex-start;
    font-size: 0.6em;
  }

  .portion {
    justify-content: flex-start;
    color: #a7a7a7;
    display: flex;
    flex-direction: column !important;
    padding-right: 8px;
  }

  &--login-prompt {
    font-size: 0.8em;
    text-align: left;
    a,
    p {
      font-family: $bebas !important;
      font-size: inherit;
      color: #a7a7a7;
    }
  }
}
