@import '@/styles/_shared.scss';

.wrapper {
  position: relative;
  cursor: pointer;

  .best-seller {
    text-align: center;
    background-color: #ffbb44;
    color: #1a507f;
    border-radius: 48px;
    min-height: 25px;
    min-width: 80px;
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 16px;
    padding: 4px 6px;
    font-family: $bebas;
    font-weight: 500;

    @media screen and (min-width: 736px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    z-index: 5;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  border-radius: 8px;
  height: 350px;
  cursor: pointer;

  &--hero-main {
    @include breakpoint-up('md') {
      height: 100%;
    }
  }

  &--hero-secondary {
    height: 250px;
  }

  @include breakpoint-down('sm') {
    width: 100%;
  }

  background-size: cover;
  background-position: initial;
  background-repeat: no-repeat;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    // Because of the hover feature
    z-index: 3;
    color: white;
    padding: 24px;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    // overflow: hidden;

    &__location {
      color: rgba(255, 255, 255, 0.7);
      margin-left: 12px;
      border-left: 1px solid rgba(255, 255, 255, 0.7);
      padding-left: 12px;
      text-transform: uppercase;
      font-family: $bebas !important;
    }

    &__title {
      font-size: 28px;
      font-family: $bebas !important;

      &--centered {
        text-align: center;
      }
    }

    &__description {
      font-weight: 500;
      font-family: $frank-ruhl;
      font-size: 14px;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
      white-space: pre-wrap;

      @include breakpoint-down('sm') {
        font-size: 16px;
      }
    }

    &__rating {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
      margin-bottom: 0px;

      & > a {
        font-weight: bold;
        color: #ffbb44;
        font-size: 14px;

        &:hover {
          color: #ffa914;
        }
      }
    }
  }

  &__button {
    width: 100%;
    border: none;
    background: $salmon;
    color: $pureWhite;
    padding: 16px;
    font-weight: 500;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 2;
  }

  &__learn-more-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background: none;
    z-index: 3;

    svg {
      box-shadow: 0 0 20px #000;
      background: $salmon;
      border: none;
      border-radius: 50px;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 16px;
      right: 16px;
      padding: 10px;
    }
  }
}
